/* radiobuttons */
$(document).on('change', '.__radiobuttons input', function(){
    if ($(this).is(':checked')) {
        $(this).closest('.__radiobuttons').find('.input_wrap > label').removeClass('__checked')
        $(this).parent().addClass('__checked');
    }
});
$('.__radiobuttons input:checked').parent().addClass('__checked');


/* checkboxes */
$(document).on('change', '.__checkboxes input', function(){
    if ($(this).is(':checked')) {
        $(this).parent().addClass('__checked');
    } else {
        $(this).parent().removeClass('__checked');
    }
});
$('.__checkboxes input:checked').parent().addClass('__checked');


/* tabpanel */
$(function(){
    var tab = $('.tabpanel-tab');
    var panel = $('.tabpanel-panel');

    tab.eq(0).addClass('__active');
    panel.eq(0).addClass('__active');

    tab.click(function(event){
        event.preventDefault();
        tab.removeClass('__active');
        $(this).addClass('__active');
        var data_set = $(this).data('set');
        panel.removeClass('__active');
        panel.filter('[data-set="' + data_set + '"]').addClass('__active');
    });
});


/* accordion */
$(function(){
    var accordion = $('.accordion');
    var title = accordion.find('.accordion-head');
    var content = accordion.find('.accordion-content');

    title.click(function(event){
        event.preventDefault();
        $(this).parents('.accordion').toggleClass('__active');
        $(this).siblings().slideToggle();
    });
});


/* modal */
$(function(){
    var trigger = $('.ModalTrigger');
    var modal = $('.modalblock');
    var close = modal.find('.modalblock-close');

    trigger.click(function(event){
        event.preventDefault();
        var modal_id = $(this).data('modal')
        modal.filter('[data-modal="' + modal_id + '"]').addClass('__active');
    });

    close.click(function(){
        modal.removeClass('__active');
    });

    $(window).click(function(event){
        if(event.target.className == "modalblock __active"){
            modal.removeClass('__active');
        }
    });

    $(document).keyup(function(e) {
        if (e.keyCode == 27) {
            $('.modablock.__active').removeClass('__active');
        }
    });
});


/* searchtoggle */
$(function(){
    var search_toggle = $('.searchtoggle');
    var wrapper = $('.wrapper');

    search_toggle.click(function(event){
        event.preventDefault();
        $(this).toggleClass('__active');
        wrapper.toggleClass('__searchactive');
    });
});

/* Main Menu */
$(function(){

    var body =  $('body');
    var nav = $('.nav');
    var burger =  $('.burger');
    var nav_trigger =  $('.nav').find('ul li');
    var nav_align = 'right';
    var break_point =  992;
    var browser_width = window.innerWidth;
    var nav_width = 300;
    var nav_window = $('<div class="navwindow"></div>');
    body.append(nav_window);
    var nav_wrap = $('.navwrap');


    nav_window.click(function(event){
        nav_reset();
    });

    //burger click
    burger.click(function(event){
        event.preventDefault();
        $(this).toggleClass('__active');
        if(body.hasClass('__mobile')){
            nav_reset();
        }else{
            nav_launch();
        };
    });

    /*nav reset*/
    var nav_reset = function nav_reset(){
        burger.removeClass('__active');
        nav_window.hide();
        body.removeClass('__mobile');
        nav_wrap.removeClass('__active');
        nav_wrap.find('ul').removeClass('__active');
    };

    /*nav launch*/
    var nav_launch = function nav_launch(){
        nav_window.show();
        body.addClass('__mobile');
        nav_wrap.addClass('__active');
    };

    if(browser_width < break_point){
        nav_trigger.click(function(event){
            event.stopPropagation();
            if($(this).children('ul').length > 0){
                event.preventDefault();
                $(this).parents('ul').removeClass('__active');
                $(this).children('ul').addClass('__active');
            };
        });
    };

    var nav_ul = $('.nav ul li > ul');
    var view_button = $('<li class="viewall"><a href="#">View</a></li>');
    var back_button = $('<li class="backto"><a href="#">Back</a></li>');

    if(browser_width < break_point){
        nav_ul.prepend(view_button);
        nav_ul.prepend(back_button);
    };

    var back_menu = $('.backto');
    back_menu.click(function(event){
        event.preventDefault();
        event.stopPropagation();
        $(this).parent('ul').removeClass('__active');
        $(this).parents('li').parent('ul').addClass('__active');
    });

    var view_all = $('.viewall');
    view_all.each(function(){
        var parent_url = $(this).closest('ul').siblings().attr('href');
        var parent_title = $(this).closest('ul').siblings().html();
        $(this).find('a').attr('href', parent_url);
        $(this).find('a').html('View ' + parent_title);
    });

    view_all.click(function(event){
        event.stopPropagation();
    });

});


/* sidemenu */
$(function(){
    var toggle = $('.sidemenu-toggle');
    toggle.click(function(event){
        event.preventDefault();
        $(this).parent().toggleClass('__active');
        $(this).siblings().slideToggle();
    });
});


/* ScrollTo */
(function(){
    $.fn.scrollto = function(options) {
        var settings = $.extend({
            trigger: '#ScrollTo',
            scroll_to: '.wrapper',
            speed: 1000,
        }, options);
        $(settings.trigger).click(function(event) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: $(settings.scroll_to).offset().top,
            }, settings.speed);
        });
    }
})();
$('#ScrollTo').scrollto({
    trigger: '#ScrollTo',
    scroll_to: '.wrapper',
    speed: 1000,
});




/*share*/
$(function(){
    // var share_toggle = $('.shareitem-trigger');
    var share_toggle = $('.shareitem');
    share_toggle.hover(function(){
        $(this).find('.shareitem-icons').toggleClass('__active');
      },function() {
        $(this).find('.shareitem-icons').removeClass('__active');
      }
    );
});


/* share social */
$(function(){
    var toggle = $('.socialshare-title');
    var shareicons = $('.socialshare-socialset');

    toggle.click(function(event){
        event.preventDefault();
        $(this).siblings('.socialshare-socialset').toggleClass('__active');
    });

});

$(function(){
    $('.field_wrap').click(function() {
        if ($(this).hasClass("error")) {
            $(this).removeClass("error")
        };
    })
})




// popup session stuff
// check if session storage variable is not true - if so, make it visible
if (sessionStorage.modalClosed == undefined) {
    $('.modalblock').addClass('__active');
};
// on close of the modal, set the session variable to true
$('#firstSessionClose').on("click",function() {
    sessionStorage.setItem('modalClosed', true);
});

// on click of the modal CTA make sure its closed fom then on
$('#modalCTA').on("click",function() {
    sessionStorage.setItem('modalClosed', true);
});

// Homepage sliders
$(function () {
    $(document).on('ready', function () {
        $(".timeline-dateslider-items").slick({
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            prevArrow: $(''),
            nextArrow: $(''),
            draggable: false,
            focusOnSelect: true,
            speed: 700,
            focusOnSelect: false,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 5
                    }
                },
            ]
        });
        $(".timeline-contentslider-items").slick({
            dots: false,
            // fade: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.timeline-dateslider-items',
            prevArrow: $('#slider-prev'),
            nextArrow: $('#slider-next'),
            mobileFirst: true,
            draggable: false,
            speed: 700,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1
                    }
                },
            ]
        });
        $(".testimonialslider-items").slick({
            dots: false,
            // fade: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: $('#testimonialslider-prev'),
            nextArrow: $('#testimonialslider-next'),
            mobileFirst: true,
            draggable: false,
            speed: 700,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1
                    }
                },
            ]
        });
    });
});
