// Load jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

// Plugin files
require('magnific-popup/dist/jquery.magnific-popup.min');
require('@accessible360/accessible-slick/slick/slick.min');
require('./plugins/pickajquery');

// Core site changes
require('./global');